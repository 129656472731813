.navbar {
    .navbar-brand {
        min-width: 10em;
        position: relative;
        display: flex;

        .logo {
            height: 4.5em;
            position: absolute;
            top: -1em;
            left: 0;
            overflow: visible;
            cursor: pointer;
            animation: 1s both fadeIn;

            /*#arturo path {
                animation: 1s both fadeIn;
                animation-delay: 0.5s;
            }

            #canez path {
                animation: 1s both fadeIn;
                animation-delay: 1s;
            }*/

            &:hover {
                transform: translate(-0.35em, -1.65em);

                path {
                    animation: 0.5s ease both pulse;
                }
            }
        }
    }
}