.animate__delay-0-5s {
    animation-delay: 0.5s;
}

.animate__delay-1s {
    animation-delay: 1s;
}

.animate__delay-1-5s {
    animation-delay: 1.5s;
}

.animate__delay-2-5s {
    animation-delay: 2.5s;
}

.animate__delay-3-5s {
    animation-delay: 3.5s;
}

.animate__delay-4-5s {
    animation-delay: 4.5s;
}

.animate__delay-5-5s {
    animation-delay: 5.5s;
}

.animate__delay-6s {
    animation-delay: 6s;
}

.animate_once {
    animation-iteration-count: 1 !important;
}


.rotation {
    animation: rotation 0.8s linear;

    &.animate_once {
        animation-timing-function: ease;
    }
}

@keyframes color {
    from {fill: red;}
    to {fill: blue;}
}

@keyframes dash {
    from {
    stroke-dashoffset: 360;
    }
    to {
    stroke-dashoffset: 40;
    }
}

@keyframes dash2 {
    from {
    stroke-dashoffset: 280;
    }
    to {
    stroke-dashoffset: -40;
    }
}

@keyframes neon {
    0%,79%,81%,82%,83%,88%,90%,95%,100% {
        stroke: rgba(45,255,196,0.9) !important;
        -webkit-filter: drop-shadow( 0px 0px 10px rgba(45,257,196,1));
        filter: drop-shadow( 0px 0px 10px rgba(45,257,196,1));
    }
    80%,80.5%,83.5%,84%,87%,91%,94% {
        stroke: rgba(0,0,0,0) !important;
        -webkit-filter: drop-shadow( 0px 0px 10px rgba(45,257,196,0));
        filter: drop-shadow( 0px 0px 10px rgba(45,257,196,0));
    }
}

@keyframes rotation {
    from {
            -webkit-transform: rotate(0deg);
    }
    to {
            -webkit-transform: rotate(180deg);
    }
}
