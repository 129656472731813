.skill {
    position: relative;

    &-item {
        text-align: end;
    }

    span {
        width: 100%;
        position: relative;
        top: -1.7rem;

        &:before, &:after {
            width: $bullet-cont;
            content: "\2022 \2022 \2022 \2022 \2022";
            color: var(--bs-gray-200);
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            font-size: 3rem;
        }

        &:after {
            width: 0;
            color: var(--bs-black);
            overflow: hidden;
            text-overflow: clip;
            transition: width 3s ease-in-out;
        }

        &[data-skill="0.5"]:after {
            width: $half-bullet-size;
        }

        &[data-skill="1"]:after {
            width: $bullet-size;
        }

        &[data-skill="1.5"]:after {
            width: calc($bullet-size + $half-bullet-size);
        }

        &[data-skill="2"]:after {
            width: calc($bullet-size * 2);
        }
        
        &[data-skill="2.5"]:after {
            width: calc(($bullet-size * 2) + $half-bullet-size);
        }
        
        &[data-skill="3"]:after {
            width: calc($bullet-size * 3);
        }
        
        &[data-skill="3.5"]:after {
            width: calc(($bullet-size * 3) + $half-bullet-size);
        }

        &[data-skill="4"]:after {
            width: calc($bullet-size * 4);
        }
        
        &[data-skill="4.5"]:after {
            width: calc(($bullet-size * 4) + $half-bullet-size);
        }

        &[data-skill="5"]:after {
            width: $bullet-cont;
        }
    }
}