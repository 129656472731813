$code-logo-duration: 20s;
$code-logo-delay: 20s;
  
@mixin setImage($a, $x) {
--name: float-up-#{$a};
--duration: calc(20s * #{$a});
left: #{$x}vw;
z-index: 100 * $a;
}
  
.tecnologias {
    width: calc(100vw - 1.5rem);
    height: 300px;
    overflow: hidden;
    position: relative;

    img {
        --duration: 40s;
        --name: float-up-1;
        position: absolute;
        top: 0;
        left: 0;
        width: 10vw;
        max-width: 256px;
        transform: translateY(200vh);
        animation: var(--name) $code-logo-duration linear infinite;
        z-index: 100;
        
        @for $i from 1 through 17 {
            &:nth-child(#{$i}) {
                animation-delay: ($i - 1) * -3s;
            }
        }
        
        &:nth-child(1)  { @include setImage(1,  0) }
        &:nth-child(2)  { @include setImage(3, 10) }
        &:nth-child(3)  { @include setImage(2, 90) }
        &:nth-child(4)  { @include setImage(1, 36) }
        &:nth-child(5)  { @include setImage(3, 62) }
        &:nth-child(6)  { @include setImage(2, 15) }
        &:nth-child(7)  { @include setImage(2, 55) }
        &:nth-child(8)  { @include setImage(3, 25) }
        &:nth-child(9)  { @include setImage(1, 68) }
        &:nth-child(10) { @include setImage(2,  0) }
        &:nth-child(11) { @include setImage(3,  80) }
        &:nth-child(12) { @include setImage(1,  45) }
        &:nth-child(13) { @include setImage(2,  10) }
        &:nth-child(14) { @include setImage(3,  70) }
        &:nth-child(15) { @include setImage(1,  50) }
        &:nth-child(16) { @include setImage(2,  40) }
        &:nth-child(17) { @include setImage(3,  60) }

        &[alt='Python'], &[alt='React'], &[alt='PHP'] {
            filter: invert(100%);
        }
    }
}
        
  @keyframes float-up-3 {
    from {
      transform: translateY(200vh) translateZ(-50vh);
    }
    to {
      transform: translateY(-200vh) translateZ(-50vh);
    }
  }
  
  @keyframes float-up-2 {
    from {
      transform: translateY(150vh) translateZ(-25vh);
    }
    to {
      transform: translateY(-150vh) translateZ(-25vh);
    }
  }
  
  @keyframes float-up-1 {
    from {
      transform: translateY(100vh);
    }
    to {
      transform: translateY(-100vh);
    }
  }