.logo-animation {
    min-height: 50vh;
    background: black url(../img/black_brick.jpg) center top repeat;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    overflow: hidden;
}

svg#animated_logo {
    max-height: 60vh;
    padding: 4em;
    overflow: visible;

    #contorno, #arturo, #canez {
        cursor: pointer;
        
        path {
            // fill: black !important;
            fill: rgba(255,255,255,0.1) !important;
            stroke: rgba(45,255,196,0.9) !important;
            stroke-width: 2px;
            -webkit-filter: drop-shadow( 0px 0px 10px rgba(45,257,196,1));
            filter: drop-shadow( 0px 0px 10px rgba(45,257,196,1));
            animation: neon 10s linear alternate infinite;
        }
    }
    
    #fondo path {
        fill-opacity: 0 !important;
    }
}