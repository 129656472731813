.curriculum {
    background: var(--bs-white);
    padding-top: 2rem;
    padding-bottom: 2rem;

    ul {
        list-style-type: none;
        padding-left: 0;
        li {
            text-align: center;

            a {
                color: var(--bs-gray-800);
            }
        }
    }
}

.curriculum, .aside {
    
    h3, h4 {
        text-transform: uppercase;

        span {
            text-transform: none;
        }
    }

    &-section {

        h3 {
            --bs-text-opacity: 1;
            color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
            font-weight: 200;
            padding-top: 1.2em;
            padding-bottom: 1em;
        }
    }

    .curriculum-section h3 svg[data-icon] {
        margin-left: -0.85em;
    }

    .experience, .education, .knowledge, .languages,
    .experiencia, .educacion, .conocimiento, .lenguajes {
        .timeline {
            text-align: right;
            border-right: solid 0.1rem var(--bs-gray-500);
            position: relative;
            padding-top: 0.6rem;
            font-size: 0.6rem;
            
            &:before {
                content: "";
                background: var(--bs-white);
                width: 0.1rem;
                height: 2.2rem;
                position: absolute;
                top: 0;
                right: -0.1rem;
                display: block;
            }

            &:after {
                content: "\2022";
                color: var(--bs-black);
                position: absolute;
                top: -1.5rem;
                right: -0.7rem;
                display: block;
                font-size: 3rem;
            }
        }
    }

    .experience-text,
    .experiencia-text {
        text-align: justify;
    }

    .knowledge-text,
    .conocimiento-text {
        color: var(--bs-gray-500);
        text-align: justify;
    }

    @media only screen and (max-width: 576px) {

        .curriculum-section, .aside-section {

            h3 {
                border-top: solid 0.1rem var(--bs-gray-500);
            }

            &:first-of-type h3 {
                padding-top: 0;
                border-top: none;
            }
        }

        .experience, .education, .knowledge, .languages,
        .experiencia, .educacion, .conocimiento, .lenguajes {
            .timeline {
                text-align: center;
                border-right: none;
                border-top: solid 0.1rem var(--bs-gray-500);
                display: flex;
                flex-direction: row;
                justify-content: center;

                &:before, &:after {
                    display: none;
                }

                > div {
                    width: auto;
                    flex-grow: 3;
                }
            }
        }

        .curriculum-section {  
            & + .experience, & + .education, & + .knowledge, & + .languages,
            & + .experiencia, & + .educacion, & + .conocimiento, & + .lenguajes {
                .timeline {
                    border-top: none;
                }
            }
        }
    }
}