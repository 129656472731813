html {
    overflow-x: hidden;
    background: var(--bs-dark);
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    span {
        display: block;
        font-weight: 200;
    }
}

.navbar {
    button {
        + button {
            margin-left: 0.5rem;
        }
    }
}

.head-container {
    min-height: 20em;
    padding-top: 4em;
    background: black url(../img/black_brick.jpg) center top repeat;
    background-size: cover;
    background-attachment: fixed;

    #img_arturo {
        max-height: 370px;
        position: relative;
        top: 4em;
        right: 0;
        z-index: 1000;
    }
}

.galaxy {
    background: black url(../img/galaxy.jpg) center top repeat;
    background-size: cover;
    background-attachment: fixed;
    z-index: -1;
}

.footer {
    background: var(--bs-dark);
    color: var(--bs-white);
    text-align: center;
}

@media only screen and (max-width: 576px) {
    .head-container {
        padding-bottom: 2em;
    }
}

.print {
    .navbar-expand-md .navbar-collapse,
    //.head-container, 
    .galaxy,
    .logo-animation,
    .curriculum ul li a[href^="pdf"] {
        display: none !important;
    }

    .navbar {
        height: 30px;
        
        .navbar-brand {
            //min-width: 5em;
            margin: 30px auto 0;
        }
    }

    .curriculum .container-fluid {
        //max-width: 8.5in;
    }

    .footer {
        color: rgba(0,0,0,0);
    }
}

.tit-nombre {
    display: none;
}

.no-contact {
    .footer {
        color: #FFFFFF;

        .copyright {
            display: none;
        }
        .tit-nombre {
            display: block;
        }
    }
    #contact, #contacto {
        display: none !important;
    }
}