@import '_vars';
@import '../../node_modules/bootstrap/scss/bootstrap';

@import '_general';
@import '_navbar';
@import '_curriculum';
@import '_skill';

@import '_coding_logos';

@import '../../node_modules/animate-sass/animate';
@import '../../node_modules/aos/dist/aos.css';
@import '_animations';
@import '_animation_logo'